import styled from 'styled-components';
import { em, rem } from 'polished';
import Input from '../Input';
import Button from '../Button';

export const AddToBasketInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .input {
    padding: ${(p) => em(p.isSmall ? 6 : 10)};
  }

  ${({ isRounded }) =>
    isRounded &&
    `
    gap: ${em(6)};
  `}
`;

export const AddToBasketInputField = styled(Input)`
  max-width: ${(p) => em(p.isSmall ? 44 : 74)};
  margin: 0;

  .inputBox {
    padding: 0;

    ${({ theme, isRounded }) =>
      isRounded &&
      `
    box-shadow: 0 0 0 ${rem(1)} ${theme.defaultPalette.monochrome[1]};
  `}
  }

  .input {
    text-align: center;
    ${({ theme, isRounded }) =>
      isRounded &&
      `
    padding:${em(6)} ${em(8)};
    font-size: ${theme.typography.size.m1};
    font-weight: 700;
  `}
  }
`;

export const ControlsButton = styled(Button)`
  ${(p) => p.theme.helpers.transition(['background', 'color'])};
  background: ${(p) => p.theme.addToBasketInput.controls.bg[p.inverse ? 'inverse' : 'default']};
  color: ${(p) => p.theme.addToBasketInput.controls.color[p.inverse ? 'inverse' : 'default']};
  width: ${(p) => em(p.isSmall ? 21 : 29)};
  height: ${(p) => em(p.isSmall ? 21 : 29)};
  margin-top: ${em(-2)};

  .content {
    font-size: ${(p) => (p.isSmall ? '1.5em' : '1.75em')};
    line-height: 0;
  }

  &:not(:disabled):hover {
    background: ${(p) =>
      p.isRounded ? p.theme.defaultPalette.monochrome[1] : p.theme.addToBasketInput.controls.bg.active};
    color: ${(p) => !p.isRounded && p.theme.addToBasketInput.controls.color.active};
  }

  ${({ theme, isRounded }) =>
    isRounded &&
    `
    border-radius: 50%;
    background-color: ${theme.defaultPalette.monochrome[0]};
    border: 1px solid ${theme.defaultPalette.monochrome[1]};
    width: ${em(39)};
    height: ${em(39)};
    min-width: ${em(39)};
    min-height: ${em(39)};

    .content {
      font-size: ${theme.typography.size.m2};
    }
  `}
`;
