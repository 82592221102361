import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';
import ADD_TO_BASKET from 'src/graphql/mutations/addToBasket.gql';
import useRESTMutation from '../useRESTMutation';
import useBalancePanelUpdater from '../useBalancePanelUpdater';

export const useAddToBasket = (
  { onCompleted, onError } = {
    onCompleted: () => null,
    onError: () => null,
  },
) => {
  const updateBalancePanel = useBalancePanelUpdater();

  const [addToBasket, addToBasketHookData] = useRESTMutation(ADD_TO_BASKET, {
    onError,
    onCompleted: (...args) => {
      onCompleted(...args);
      updateBalancePanel();
    },
    update: (cache, { data: responseData }) => {
      const { localState } = cache.readQuery({
        query: GET_LOCAL_STATE,
      });

      cache.writeQuery({
        query: GET_LOCAL_STATE,
        data: {
          localState: {
            ...localState,
            basketSummary: {
              value: responseData.addToBasket.value || '0',
              quantity: responseData.addToBasket.lines || '0',
              currencySign: responseData.addToBasket.currencysig,
              priceType: responseData.addToBasket.pricetype.type,
            },
          },
        },
      });
    },
  });

  return [addToBasket, addToBasketHookData];
};
