import styled, { css } from 'styled-components';
import Button from '../Button';

export const AddToBasketButton = styled(Button)`
  ${({ forceFullOpacity }) => {
    if (forceFullOpacity) {
      return css`
        opacity: 1 !important;
      `;
    }

    return css``;
  }};

  & > .inner {
    .icon,
    .spinner {
      font-size: 1.5em;
    }
  }
`;
