import { useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client/react';
import GET_LOCAL_STATE from 'src/graphql/queries/getLocalState.gql';
import GET_BALANCE_PANEL from 'src/graphql/queries/getBalancePanel.gql';
import SET_CACHED_BALANCE_PANEL from 'src/graphql/mutations/setCachedBalancePanel.gql';
import { clientTypesKeys, clientTypesMap } from 'src/enums/clientsTypes';
import useLazyShopQuery from '../useLazyShopQuery';

export const useBalancePanelUpdater = () => {
  const {
    data: { localState },
  } = useQuery(GET_LOCAL_STATE);
  const [getBalancePanelData, { data: balancePanelData, error }] = useLazyShopQuery(GET_BALANCE_PANEL);
  const [setCachedBalancePanel] = useMutation(SET_CACHED_BALANCE_PANEL);

  const getAndUpdateCachedDataForBusinessClient = useCallback(() => {
    if (localState?.client?.clientType === clientTypesMap.get(clientTypesKeys.CLIENT_B2B)) {
      getBalancePanelData();
    }
  }, [localState, getBalancePanelData]);

  useEffect(() => {
    if (!error && balancePanelData) {
      const { balancePanelResponse } = balancePanelData;

      if (balancePanelResponse) {
        setCachedBalancePanel({
          variables: {
            paymentType: balancePanelResponse.paymenttype,
            availableBalance: balancePanelResponse.freebalance,
            currencySign: balancePanelResponse.currency,
          },
        });
      }
    }
  }, [balancePanelData, error, setCachedBalancePanel]);

  return getAndUpdateCachedDataForBusinessClient;
};
