import useTranslation from 'src/hooks/useTranslation';
import TooltipPopper from '../TooltipPopper';
import * as S from './styles';

const defaultProps = {
  isProductInBasket: false,
  isDraftEqualToZeroValue: false,
  onAdd: () => null,
  busy: false,
  hideText: false,
  draft: null,
  isPartial: false,
  defaultVariant: 'quaternary',
  disabledVariant: 'primary',
  forceFullOpacity: false,
  wasBuyEarlier: false,
};

export const AddToBasketButton = ({
  isPartial,
  isProductInBasket,
  isDraftEqualToZeroValue,
  busy,
  hideText,
  draft,
  onAdd,
  defaultVariant,
  disabledVariant,
  forceFullOpacity,
  wasBuyEarlier,
  customLabel,
  hideIcon,
  ...restProps
} = defaultProps) => {
  const { t } = useTranslation();
  const { t: tTooltip } = useTranslation('tooltips');

  let addToBasketButtonIcon = wasBuyEarlier ? 'buyAgain' : 'cart';
  let variant = defaultVariant;
  let buttonLabelKey = isPartial ? 'selectBasketPart' : !!customLabel ? customLabel : 'addToBasket';
  let buttonLoadingLabelKey = 'loading';

  if (!isPartial) {
    buttonLoadingLabelKey = isProductInBasket ? 'updatingBasket' : 'adding';
  }

  if (!isPartial && draft && !isDraftEqualToZeroValue) {
    addToBasketButtonIcon = isProductInBasket ? 'reload' : 'arrowRight';
    buttonLabelKey = isProductInBasket ? 'updateBasket' : 'addToBasket';
  }

  if (draft && isDraftEqualToZeroValue) {
    addToBasketButtonIcon = 'close';
    buttonLabelKey = isPartial ? 'removeBasketPart' : 'removeFromBasket';
  }

  if (!draft && isProductInBasket) {
    variant = disabledVariant;
    buttonLabelKey = 'inBasket';
  }

  const buttonLabel = t(buttonLabelKey);
  const busyButtonLabel = t(buttonLoadingLabelKey);
  const busyTextContent = !hideText ? busyButtonLabel : null;
  const textContent = !hideText ? buttonLabel : null;
  const buttonAriaLabel = busy ? busyButtonLabel : buttonLabel;
  const isTooltip = addToBasketButtonIcon === 'buyAgain';

  return (
    <TooltipPopper
      title={isProductInBasket ? tTooltip('buyAgainProductIsInBasket') : tTooltip('addToBasketBuyAgain')}
      trigger="mouseenter"
      disabled={!isTooltip}
      className="add-to-basket-tooltip"
    >
      <S.AddToBasketButton
        variant={variant}
        icon={hideIcon ? '' : addToBasketButtonIcon}
        iconBefore={false}
        onClick={onAdd}
        busyChildren={busyTextContent}
        busy={busy}
        aria-label={hideText ? buttonAriaLabel : null}
        inverseBusyIcon
        // NOTE: https://jira.unity.pl/browse/ONNINB2B-982 point 3.
        // disabled={!draft && isProductInBasket}
        forceFullOpacity={forceFullOpacity}
        {...restProps}
      >
        {textContent}
      </S.AddToBasketButton>
    </TooltipPopper>
  );
};

AddToBasketButton.defaultProps = defaultProps;
