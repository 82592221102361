import { useState, useEffect } from 'react';
import { countDecimals } from '../../utils/countDecimals';
import * as S from './styles';
import { isEnterKey } from 'src/utils/isEnterKey';

const EMPTY_STRING = '';

const defaultProps = {
  delimiter: ',',
  small: false,
  inverse: false,
  busy: false,
  readOnly: false,
  showZeroPlaceholder: false,
  forceDisabled: false,
  onClickOverride: null,
  onUpdate: () => null,
  onEnterKeyDown: () => null,
  onBlur: () => null,
  transformValueBeforeUpdate: (v) => v,
  onChangeValueByButtonClick: (v) => v,
  variant: '',
};

const currentToNumeric = (current = 0, delimiter = ',') => {
  const allDelimiterOccurrences = new RegExp(`${delimiter}`, 'gmi');
  return +current.toString().replace(allDelimiterOccurrences, '.');
};

const numericToCurrent = (numeric = 0, min = 0, delimiter = ',') => {
  if (numeric <= min) return EMPTY_STRING;
  return numeric.toString().replace('.', delimiter);
};

const prepareControlButtonHandler = (
  stepValue,
  limitValue,
  min,
  delimiter,
  showZeroPlaceholder,
  transformValueBeforeUpdate,
) => (currentValue) => {
  const currentValueAsNum = currentToNumeric(currentValue, delimiter);
  const currentValueAsNumWithStep = currentValueAsNum + stepValue;
  const isLimitReached = currentValueAsNumWithStep - stepValue === limitValue;
  const quotaToAddIfCurrentValueIsMinAndAdding =
    stepValue > 0 && currentValueAsNum === min && !showZeroPlaceholder ? stepValue : 0;
  const limitSafeStepValue = isLimitReached ? 0 : stepValue;
  const stepValueToAdd = quotaToAddIfCurrentValueIsMinAndAdding + limitSafeStepValue;
  const nextValueAsNumUnformatted = currentValueAsNum + stepValueToAdd;
  const currentValueWithStepString = nextValueAsNumUnformatted.toFixed(countDecimals(nextValueAsNumUnformatted));

  return numericToCurrent(transformValueBeforeUpdate(+currentValueWithStepString, true), min, delimiter);
};

const prepareLimitChecker = (current) => (limitValue) => +current === +limitValue;

export const AddToBasketInput = ({
  id,
  label,
  nowInBasket,
  delimiter,
  min,
  max,
  step,
  small,
  inverse,
  busy,
  showZeroPlaceholder,
  readOnly,
  transformValueBeforeUpdate,
  forceDisabled,
  onClickOverride,
  onUpdate,
  onEnterKeyDown,
  onBlur,
  onChangeValueByButtonClick,
  handleChangeItemQuantityForChangeDeliveryInfo,
  variant,
  ...restProps
} = defaultProps) => {
  const [current, setCurrent] = useState(min);
  const checkIfLimitReached = prepareLimitChecker(current);
  const maskRegExp = new RegExp(`^[0-9]+(${delimiter}[0-9]{0,})?$`, 'gmi');
  const isRounded = variant === 'rounded';

  const decrementState = prepareControlButtonHandler(
    -1 * step,
    min,
    min,
    delimiter,
    showZeroPlaceholder,
    transformValueBeforeUpdate,
  );

  const incrementState = prepareControlButtonHandler(
    step,
    max,
    min,
    delimiter,
    showZeroPlaceholder,
    transformValueBeforeUpdate,
  );

  const hasOnClickOverride = onClickOverride && typeof onClickOverride === 'function';

  const overrideOrFireClickCallback = (e, clickCallback) => {
    if (hasOnClickOverride) {
      onClickOverride(e);
    } else {
      clickCallback(e);
    }
  };

  const handleIncButtonClick = (e) =>
    overrideOrFireClickCallback(e, () =>
      setCurrent((state) => {
        const newState = incrementState(state);
        if (handleChangeItemQuantityForChangeDeliveryInfo) {
          handleChangeItemQuantityForChangeDeliveryInfo(currentToNumeric(newState, delimiter));
        }
        /* eslint-disable-next-line */

        if (onChangeValueByButtonClick) {
          onChangeValueByButtonClick(newState);
        }
        return newState;
      }),
    );
  const handleDecButtonClick = (e) =>
    overrideOrFireClickCallback(e, () =>
      setCurrent((state) => {
        const newState = decrementState(state);
        if (handleChangeItemQuantityForChangeDeliveryInfo) {
          handleChangeItemQuantityForChangeDeliveryInfo(currentToNumeric(newState, delimiter));
        }
        /* eslint-disable-next-line */
        if (onChangeValueByButtonClick) {
          onChangeValueByButtonClick(newState);
        }
        return newState;
      }),
    );
  const handleInputBlur = (e) => {
    if (hasOnClickOverride) return;

    let nextValueNumeric = currentToNumeric(e.target.value, delimiter);

    if (nextValueNumeric > max) {
      nextValueNumeric = max;
    }

    if (nextValueNumeric < min) {
      nextValueNumeric = min;
    }

    const nextValue = numericToCurrent(transformValueBeforeUpdate(nextValueNumeric, false), min, delimiter);

    if (nextValue === EMPTY_STRING) {
      setCurrent(min);
    } else {
      setCurrent(nextValue);
    }

    if (onBlur && typeof onBlur === 'function') {
      onBlur(current);
    }
  };

  const handleInputKeyDown = (e) => {
    if (hasOnClickOverride) return;

    if (isEnterKey(e.code)) {
      e.target.blur();
      onEnterKeyDown(current);
    }
  };

  const handleInputValueUpdate = (e) => {
    if (handleChangeItemQuantityForChangeDeliveryInfo) {
      handleChangeItemQuantityForChangeDeliveryInfo(currentToNumeric(e.target.value, delimiter));
    }

    if (hasOnClickOverride) return;

    setCurrent(e.target.value);
  };

  const handleInputFocus = (e) => {
    if (!hasOnClickOverride) return;

    e.target.blur();
    onClickOverride(e);
  };

  useEffect(() => {
    onUpdate(currentToNumeric(current, delimiter));
  }, [current, onUpdate, delimiter]);

  useEffect(() => {
    if (!busy) {
      setCurrent(numericToCurrent(nowInBasket, min, delimiter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowInBasket, min, delimiter]);

  return (
    <S.AddToBasketInput isSmall={small} isRounded={isRounded} {...restProps}>
      <S.ControlsButton
        inverse={inverse}
        isSmall={small}
        blank
        disabled={forceDisabled ? true : readOnly || busy || checkIfLimitReached(min)}
        onClick={handleDecButtonClick}
        isRounded={isRounded}
        className="minus"
      >
        −
      </S.ControlsButton>

      <S.AddToBasketInputField
        variant="quinary"
        isRounded={isRounded}
        id={id}
        label={label}
        placeholder={showZeroPlaceholder ? '0' : numericToCurrent(step, min, delimiter)}
        value={current === min ? EMPTY_STRING : current}
        mask={maskRegExp}
        isSmall={small}
        disabled={forceDisabled ? true : readOnly || busy}
        hideLabel
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onInput={handleInputValueUpdate}
        onChange={handleInputValueUpdate}
        onKeyDown={handleInputKeyDown}
        className="inputContainer"
      />

      <S.ControlsButton
        inverse={inverse}
        isSmall={small}
        blank
        disabled={forceDisabled ? true : readOnly || busy || checkIfLimitReached(max)}
        onClick={handleIncButtonClick}
        isRounded={isRounded}
        className="plus"
      >
        +
      </S.ControlsButton>
    </S.AddToBasketInput>
  );
};

AddToBasketInput.defaultProps = defaultProps;
